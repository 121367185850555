import { Injectable } from '@angular/core';
import * as Parse from 'parse';
import { BehaviorSubject } from 'rxjs';
import { CalendarEvent } from 'angular-calendar';
import { GoogleCalendarEvent } from 'backend/interfaces/calendar.interface';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Student } from 'backend/interfaces/student.interface';
import * as moment from "moment";
import { Course, CourseSearchCriteria } from 'backend/interfaces/course.interface';
import { ParentConsent } from 'backend/interfaces/parent.interface';
import { Question } from 'backend/interfaces/question.interface';
import { Order } from 'backend/interfaces/order.interface';

@Injectable({
  providedIn: 'root'
})

export class CourseService {
  courses = new BehaviorSubject<Course[] | null>(null);
  courses$ = this.courses.asObservable();
  orders = new BehaviorSubject<Order[] | null>(null);
  orders$ = this.orders.asObservable();
  activatedOrder = new BehaviorSubject<Order>(null);
  activatedOrder$ = this.activatedOrder.asObservable();
  courseSearch = new BehaviorSubject<{name:string} | null>(null);
  courseSearch$ = this.courseSearch.asObservable();
  enrollments = new BehaviorSubject<any[] | null>(null);
  enrollments$ = this.enrollments.asObservable();
  activatedEnrollment = new BehaviorSubject<any>(null);
  activatedEnrollment$ = this.activatedEnrollment.asObservable();
  activatedCourse = new BehaviorSubject<Course>(null);
  activatedCourse$ = this.activatedCourse.asObservable();
  activatedDrive = new BehaviorSubject<any[]>(null);
  activatedDrive$ = this.activatedCourse.asObservable();

  courseCount = new BehaviorSubject<number | null>(null);
  courseCount$ = this.courseCount.asObservable();

  courseRequests = new BehaviorSubject<Course[] | null>(null);
  courseRequests$ = this.courseCount.asObservable();
  uploadToken: string;
  public newCourseEmail: string;
  constructor(private http: HttpClient) {
  }

  saveCourse(course: Course): Promise<Course> {
    return Parse.Cloud.run('saveCourse', { course }).then((result) => {
      return result;
    });
    
  }

  saveCourseConsent(courseConsent: ParentConsent): Promise<Course> {
    return Parse.Cloud.run('saveCourseConsent', { courseConsent }).then((result) => {
      return result;
    });
  }


  getCourses(displayLimit: number, page: number): Promise<void> {
    return Parse.Cloud.run('getCourses', { displayLimit, page }).then((result) => {
      this.courses.next(result);
    });
  }

  getOrders(displayLimit: number, page: number): Promise<void> {
    return Parse.Cloud.run('getOrders', { displayLimit, page }).then((result) => {
      this.orders.next(result);
    });
  }

  getOrderById(id: string): Promise<Order> {    
    return Parse.Cloud.run('getOrderById', { id }).then(res => {
      this.activatedOrder.next(res)
    });
  }

  getEnrollmentById(id: string): Promise<Order> {    
    return Parse.Cloud.run('getEnrollmentById', { id })
  }

  getCourseById(id: string): Promise<Course> {    
    return Parse.Cloud.run('getCourseById', { id }).then(res => {
      this.activatedCourse.next(res)
    });
  }

  getStudentsByCourseId(id: string, mainContact: boolean): Promise<Student[]> {
    return Parse.Cloud.run('getStudentsByCourseId', { id, mainContact });
  }

  getEnrollmentsByUserId(id: string): Promise<Course[]> {
    return Parse.Cloud.run('getEnrollmentsByUserId', { id })
  }

  getEnrollmentsByOrderKey(id: string): Promise<Course[]> {
    return Parse.Cloud.run('getEnrollmentsByOrderKey', { id });
  }

  getCourseByEmail(email: string): Promise<Course> {
    return Parse.Cloud.run('getCourseByEmail', { email });
  }

  getCalendarBusy(): Promise<any>{
    const from = "2023-01-01T00:00:00.000Z";
    const to = "2023-04-01T00:00:00.000Z";
    return Parse.Cloud.run('getCalendarBusy', { from, to });
  }

  getCalendarEvents(): Promise<any>{
    return Parse.Cloud.run('getCalendarEvents');
  }

  getCourseCount(): Promise<number>{
    return Parse.Cloud.run('getCourseCount').then((result) => {
      this.courseCount.next(result);
    });;
  }

  getCourseQuestions(courseId: string, section: string): Promise<Question[]> {
    return Parse.Cloud.run('getCourseQuestions', { courseId, section});
  }

  createBooking(event: GoogleCalendarEvent): Promise<any>{
    return Parse.Cloud.run('createBooking', { event });
  }

  updateBooking(event: GoogleCalendarEvent): Promise<any>{
    return Parse.Cloud.run('updateBooking', { event });
  }

  deleteBooking(id: string): Promise<any>{
    return Parse.Cloud.run('deleteBooking', { id });
  }

  deleteCourse(id: string): Promise<any>{
    return Parse.Cloud.run('deleteCourse', { id });
  }

  deleteOrder(id: string): Promise<any>{
    return Parse.Cloud.run('deleteOrder', { id });
  }

  searchCourse(criteria: CourseSearchCriteria): Promise<any>{
    return Parse.Cloud.run('searchCourse', { criteria });
  }

  getCourseRequests(status: string): Promise<any>{
    return Parse.Cloud.run('getCourseRequests', { status }).then((result) => {
      this.courseRequests.next(result);
    });
  }

  saveProfilePicture(courseId: string, image: any): Promise<any>{
    return Parse.Cloud.run('saveProfilePicture', { courseId, image });
  }

  getCourseFile(id: string, mimeType: string): Promise<any>{
    return Parse.Cloud.run('getFileById', { id, mimeType })
  }

  getcourseFiles(fileId: string): Promise<any>{
    return Parse.Cloud.run('getFilesById', { fileId })
  }

  saveCourseQuestions(courseId: string, questions: any[], section: string): Promise<any>{
    return Parse.Cloud.run('saveCourseQuestions', { courseId, questions, section })
  }

  getAlbumList(id: string): Promise<any>{
    return Parse.Cloud.run('getAlbumList', { id })
  }

  getAlbumById(id: string): Promise<any>{
    return Parse.Cloud.run('getAlbumById', { id })
  }

  createDriveFolder(courseId: string): Promise<any>{
    return Parse.Cloud.run('createCourseDriveFolder', { courseId })
  }

  createAccount(): Promise<any>{
    return Parse.Cloud.run('saveAccount')
  }

  createPhotoAlbum(courseId: string): Promise<any>{
    return Parse.Cloud.run('createPhotoAlbum', { courseId })
  }

  uploadFile(id: string, uploadedFile: any, description: string): Promise<any>{
    return Parse.Cloud.run('uploadFile', { id, uploadedFile, description })
  }

  async uploadPhoto(id: string, uploadedFile: any, description: string): Promise<any>{
    const authToken = await Parse.Cloud.run('getGToken', { });
    let body = uploadedFile.source;
    let newMediaItems = [];

    const reqHeaders = new HttpHeaders({
        Authorization: 'Bearer '+ authToken,
        'Content-type': 'application/octet-stream',
        'X-Goog-Upload-Content-Type': uploadedFile.type,
        'X-Goog-Upload-File-Name': uploadedFile.name,
        'X-Goog-Upload-Protocol': 'raw'
      })
    

      const batchCreateHeaders = new HttpHeaders({
        Authorization: 'Bearer '+ authToken,
        'Content-type': 'application/json'
      })
    
    let uploadToken = await this.http.post(
      'https://photoslibrary.googleapis.com/v1/uploads',
      body, {headers: reqHeaders, responseType: 'text'}).toPromise(); 

      // uploadToken.tap(res => {
    newMediaItems.push(
          {
            description: uploadedFile.description,
            simpleMediaItem: {
              fileName: uploadedFile.name,
              uploadToken: uploadToken
            }
          });

      return this.http.post(
            'https://photoslibrary.googleapis.com/v1/mediaItems:batchCreate',
            { albumId: id, newMediaItems }, {headers: batchCreateHeaders}).toPromise();

    // return Parse.Cloud.run('uploadPhoto', { id, uploadedFile })

  }

  generateKey(courseId: string, quantity: number, customerRef: string, expiryDate: string): Promise<void>{
    return Parse.Cloud.run('generateCourseKey', { courseId, quantity, customerRef, expiryDate })
  }

  removeFile(id: string): Promise<any>{
    return Parse.Cloud.run('removeFileById', { id })
  }
}

