import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import {BreakpointObserver} from '@angular/cdk/layout';
import {MatStepper, StepperOrientation} from '@angular/material/stepper';
import {map} from 'rxjs/operators';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import * as moment from "moment";
import { Course, CourseConsent } from 'backend/interfaces/course.interface';

import { AuthService } from 'src/app/auth/auth.service';
import { GroupService } from 'src/app/admin/services/group.service';


import { Group } from 'backend/interfaces/group.interface';
import { CourseStatus } from 'backend/enum/CourseStatus.enum';
import { CourseService } from 'src/app/admin/services/course.service';
@Component({
  selector: 'app-course-profile',
  templateUrl: './course-profile.component.html',
  styleUrls: ['./course-profile.component.scss']
})
export class CourseProfileComponent implements OnInit {
  @Input() profile: Course;
  currentUser;
  categories: Group[] = []
  saving = false;
  @Output() emitProfile: EventEmitter<any> = new EventEmitter();
  @Output() emitMedical = new EventEmitter<Object>();
  @ViewChild('stepper') private stepper: MatStepper;
  constructor(private fb: UntypedFormBuilder, private breakpointObserver: BreakpointObserver, private courseService: CourseService, private router: Router, private _snackBar: MatSnackBar, private authService: AuthService, private groupService: GroupService) {
    this.stepperOrientation = breakpointObserver.observe('(min-width: 800px)')
      .pipe(map(({matches}) => matches ? 'horizontal' : 'vertical'));

      this.authService.$User.subscribe((res)=>{
        if(res){
          this.currentUser = res;
        }
      })
  }
  profileForm: UntypedFormGroup;
  medicalForm: UntypedFormGroup;
  thirdFormGroup = this.fb.group({
    thirdCtrl: ['', Validators.required]
  });
  stepperOrientation: Observable<StepperOrientation>;
  async ngOnInit(): Promise<void> {
    this.groupService.groups$.subscribe(res => {
      if (res) {
        this.categories = res;
      }
    });
    this.courseService.activatedCourse$.subscribe(async res => {
      if(res){
      this.profile = res;
      this.profile.id = res.id;
      }else{
        this.stepper?.reset();
        this.profile = {
          id: '',
          Name: '',
          RegistrationNumber: '',
          Category: this.categories[0],
          Pricing: '',
          Description: '',
          PublicLink: '',
          UnitStandard: '',
          RegistrationDate: '',
          CloseDate: '',
          Status: CourseStatus.CREATED,
          Slides: '',
        };
      }
    });

    this.profileForm = this.fb.group({
      Name: this.profile ? this.profile.Name : '',
      Category: [this.profile.Category ? this.categories.find(c => c.id === this.profile.Category.id) : null, Validators.required],
      RegistrationNumber: this.profile ? this.profile.RegistrationNumber : '',
      Pricing: this.profile ? this.profile.Pricing : '',
      Description: this.profile ? this.profile.Description : '',
      PublicLink: this.profile ? this.profile.PublicLink : '',
      UnitStandard: this.profile ? this.profile.UnitStandard : '',
      RegistrationDate: this.profile ? this.profile.RegistrationDate : '',
      CloseDate: this.profile ? this.profile.CloseDate : '',
      Slides: this.profile ? this.profile.Slides : '',
      Status: this.profile ? this.profile.Status.length !== 0 ? this.profile.Status : CourseStatus.CREATED : CourseStatus.CREATED,
    });

  }
  async saveProfile(){
    console.log(this.profileForm.value)
    if(this.profileForm.dirty){
      this.saving = true;
      this.courseService
      .saveCourse({...this.profileForm.value, id: this.profile.id})
      .then((res) => {
        this.courseService.newCourseEmail = undefined;
        this.openSnackBar('Course saved');
        this.stepper.next();
        this.courseService.getCourses(10,0);
        this.courseService.getCourseCount();
        this.courseService.activatedCourse.next(res);
        this.saving = false
      })
      .catch((err) => {
        this.openSnackBar(err);
        this.saving = false;
      });
      this.profileForm.markAsPristine();
    } else {
      this.stepper.next();
    }
  }
  getErrorMessage() {
    if (this.profileForm.controls['Name'].hasError('required')) {
      return 'You must enter a value';
    }
  
    return this.profileForm.controls['Name'].hasError('required') ? 'Not a valid name' : '';
  }

  async saveCourseConsent(event: {student: CourseConsent, signature: string}){
    this.saving = true;
    this.courseService
      .saveCourseConsent(event.student)
      .then(() => {
        this.openSnackBar('Consent saved');
        this.emitProfile.emit(event.student);
        this.saving = false;
      })
      .catch((err) => {
        this.openSnackBar(err);
        this.saving = false;
      });
  }


  back(){
    this.stepper.previous();
  }

  openSnackBar(message: string) {
    this._snackBar.open(message, 'OK', {
      duration: 3000
    });
  }
}




