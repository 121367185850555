<!-- <h2>{{ course.Name }}</h2> -->

<!-- <ng-container *ngIf="questions.length > 0">
    <app-course-question *ngFor="let q of questions" [question]="q"></app-course-question>
</ng-container> -->

<div class="d-flex justify-content-center loader flex-column align-items-center" *ngIf="loading">
  <div>
    <mat-spinner color="accent"></mat-spinner>
  </div>
</div>
<form *ngIf="answersForm" [formGroup]="answersForm" class="h-100 d-flex flex-column">
  <div formArrayName="answers">
    <mat-toolbar class="mobile bg-light">
      <div class="w-100 d-flex justify-content-between bg-grey p-3">
        <button (click)="stepper.previous()" type="button" [disabled]="saving" mat-raised-button color="default">
          Previous
        </button>  
        <button (click)="stepper.next()" type="button" [disabled]="saving" mat-raised-button color="primary">
          Next Question
        </button>    
      </div>
    </mat-toolbar>
    <mat-stepper #stepper
    [orientation]="(stepperOrientation | async)!" class="bg-light">
    <ng-container
      *ngFor="let control of answersFieldAsFormArray?.controls; let i = index" [formGroupName]="i"
    >
      <mat-step class="p-3 m-3 bg-light border-primary" [completed]="control.valid && control.controls['answerType'].value !== 'List'" [stepControl]="control">
        <ng-template matStepLabel>Q{{i+1}}</ng-template>

          <form [formGroup]="control">
        <div class="d-flex justify-content-between">
          <mat-label class="mb-3"><b> Question {{ i + 1 }} {{answersFieldAsFormArray ? 'of '+ answersFieldAsFormArray.controls.length : ''}}</b></mat-label>
          <mat-label>
            Points: {{control.controls['points'].value}}
        </mat-label>
        </div>
        <div class="d-flex flex-column">
          <!-- <mat-label *ngIf="control.controls['note'].value">
            {{control.controls['note'].value}}
        </mat-label> -->
          <mat-label class="mb-3">
              <h2 class="m-0">{{control.controls['title'].value}}</h2>
          </mat-label>
          <mat-label *ngIf="control.controls['body'].value" class="mb-3">
              <h2 class="m-0">{{control.controls['body'].value}}</h2>
          </mat-label>
          <mat-label *ngIf="control.controls['duration'].value">
              {{control.controls['duration'].value}}
          </mat-label>
          <hr>
          <div class="mt-1" *ngIf="control.controls['answer']">
            <div class="mt-3 mat-form-field w-100 d-flex flex-wrap" [ngSwitch]="control.controls['answerType'].value">
              <img *ngIf="control.controls['imageId'].value" [src]="'https://drive.google.com/thumbnail?id='+control.controls['imageId'].value+'&sz=w350'">
              <div class="d-flex flex-1 flex-column">
                <mat-label class="mb-3">
                  Answer
                </mat-label>
                <mat-radio-group required *ngSwitchCase="'TrueFalse'" aria-label="Select an option" name="answer" formControlName="answer" >
                  <mat-radio-button value="True">True</mat-radio-button>
                  <mat-radio-button class="ml-3" value="False">False</mat-radio-button>
                </mat-radio-group>
                <mat-radio-group class="d-flex flex-column" required *ngSwitchCase="'Select'" aria-label="Select an option" formControlName="answer">
                  <mat-radio-button [value]="option" *ngFor="let option of control.controls['options'].value">{{option}}</mat-radio-button>
                </mat-radio-group>
                <textarea required class="w-100" *ngSwitchCase="'Long text'" rows="5" matInput name="answer" formControlName="answer" ></textarea>
                <div *ngSwitchCase="'List'" formArrayName="listItems" class="flex-1 d-flex flex-column justify-content-between">
                    <div *ngFor="let item of control.controls['listItems'].value; let j = index" class="d-flex w-100 align-items-center">
                      <p class="m-0 p-2" *ngIf="control.controls['listLabelType'].value === 'letters'">{{getAphabetLetter(j)}}</p>
                      <p class="m-0 p-2" *ngIf="control.controls['listLabelType'].value === 'numbers'">{{j+1}}</p>
                      <input class="flex-1" matInput (change)="updateAnswerListItem($event, i, j)" [value]="item"/>
                  </div>
                </div>
                <input required class="w-100" *ngSwitchDefault matInput name="answer" formControlName="answer">
                </div>
              </div>
  
            </div>
  </div>
  </form>
      </mat-step>
    </ng-container>
  </mat-stepper>
  </div>
  <div class="desktop w-100 d-flex justify-content-between p-3">
    <button (click)="stepper.previous()" type="button" [disabled]="saving" mat-raised-button color="default">
      Previous
    </button>  
    <button (click)="stepper.next()" type="button" [disabled]="saving" mat-raised-button color="primary">
      Next Question
    </button>    
  </div>
  <div class="w-100 d-flex justify-content-end p-3 mt-3 flex-column border-primary" *ngIf="expired">
    <p class="text-danger">Expired: {{course.Order.get('ExpiryDate') | date}}</p>
  </div>
  <div class="w-100 d-flex justify-content-end p-3 mt-3 flex-column border-primary" *ngIf="!expired">
<button (click)="save()" type="button" [disabled]="saving" mat-flat-button color="primary">
  Save and continue
</button>
<button [disabled]="answersForm.invalid" class="mt-3" (click)="submit()" type="button" [disabled]="saving" mat-raised-button color="accent">
  Submit
</button>
</div>
</form>

